'use strict'
;(() => {
  const wrappers = document.getElementsByClassName('spir-widget') || []
  function renderIframe(i: number) {
    const wrapper = wrappers[i]
    if (!wrapper) return null
    const src = wrapper.getAttribute('data-url')
    if (!src) return null
    const iframe = document.createElement('iframe')
    const iframeName = 'spir-widget-iframe-' + i
    iframe.setAttribute('width', '100%')
    iframe.setAttribute('name', iframeName)
    iframe.setAttribute('height', '100%')
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute('src', src + window.location.search)
    wrapper.appendChild(iframe)
  }

  window.addEventListener('message', event => {
    if (event.data.type === 'REDIRECT_EXTERNAL_CONFIRMATION_PAGE') {
      if (window.top) {
        window.top.location.href = event.data.value.url
      } else {
        window.location.href = event.data.value.url
      }
    }
  })

  for (let i = 0; i < wrappers.length; i++) {
    renderIframe(i)
  }
})()
